import { BootResponse, LoginResponse } from 'constants/apiResponses.types';
import {
    EEventsType,
    EFeatureFlag,
    ELocalStorageKeys,
    ELoginEvents,
    ELoginMethods,
    ELoginMode,
    ELoginResultReason,
    EPlatformType,
    EQueryParams,
    EResultOptions,
    ESessionStorageKeys,
    EStorePhase
} from 'constants/enums';
import useFacebook from 'hooks/useFacebook';
import { getPlatformData } from 'utils';
import { webStorageUtil } from 'state/webStorage.state.service';
import useGoogle from 'hooks/useGoogle';
import useUrlQuery from 'hooks/useUrlQuery';
import useCustomEvents from 'hooks/useCustomEvents';
import useLoginEvents from 'hooks/useLoginEvents';
import { useNavigate } from 'react-router-dom';
import { useObservable } from 'state/observable/observable.state.hook';
import { LoginData, LoginRequestData } from 'pages/login/types';
import useApi from 'hooks/useApi';
import { browserName, isAndroid, isIOS, isMobile, osName } from 'react-device-detect';
import { loginStateService } from 'state/login.state.service';
import { useTranslation } from 'react-i18next';
import { useAppState } from './app.state.hook';
import { loginPageService } from 'pages/login/login-page.service';
import useIndexedDB from 'hooks/useIndexedDB';

export const useLoginState = () => {
    const API = useApi({
        platform: isMobile ? osName : browserName
    });
    const publisherMetaData = API.getPublisherMeta.data as BootResponse;
    const facebook = useFacebook(publisherMetaData.integration.playersAuthentication.fbAppId);
    const google = useGoogle();
    const localAddress = useUrlQuery(EQueryParams.LOCAL_ADDRESS);
    const customEvents = useCustomEvents();
    const { sendPublisherWebhookLoginEvent, calcLoginDuration, recordLoginStartTime } =
        useLoginEvents();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setDisplayLoader } = useAppState();
    const { addValue } = useIndexedDB(['sessions']);

    const storeSessionTokenInDB = async (sessionToken: string, id: string) => {
        try {
            await addValue.mutateAsync({
                tableName: 'sessions',
                value: {
                    id: id,
                    sessionToken: sessionToken
                }
            });
        } catch (error) {
            console.error('Error storing session token in DB', error);
        }
    };

    const failedLoginHandler = async (method: string, msg?: string, enrichedCustomMsg?: string) => {
        const loginFailureData = {
            result: EResultOptions.FAILED,
            reason: msg || enrichedCustomMsg || ELoginResultReason.UNKNOWN
        };
        customEvents.sendCustomEvent(
            EEventsType.LOGIN_RESULT,
            {
                authMethod: method,
                ...loginFailureData,
                login_duration: calcLoginDuration(),
                platform: getPlatformData()
            },
            EStorePhase.PRE_LOGIN
        );
        sendPublisherWebhookLoginEvent({
            eventName: ELoginEvents.LOGIN_RESOLVED,
            method: method as ELoginMethods,
            options: loginFailureData
        });
        const queryParams = new URLSearchParams({
            error: 'auth',
            ...(msg && { msg })
        });
        navigate(`/failed?${queryParams.toString()}`, {
            state: enrichedCustomMsg ? { enrichedCustomMsg } : undefined
        });
    };

    const handleLoginError = async (error: any, authMethod: string) => {
        const defaultErrorMessage = t('login.errorMessages.loginFailed');
        const { enrichedMessage, code, message } = error.response?.data || {};
        const errorCode = error.response?.data?.code;
        const errorMessage = enrichedMessage
            ? undefined
            : code === 100
                ? message
                : errorCode >= 500 && errorCode <= 3000
                    ? t('login.errorMessages.serviceIsDown')
                    : defaultErrorMessage;
        await failedLoginHandler(authMethod, errorMessage, enrichedMessage);
    };

    const loginResponse = async (response: LoginData) => {
        setDisplayLoader(true);
        try {
            const loginPayload = prepareLoginPayload(response);
            const { data } = await performLogin(loginPayload);
            await processSuccessfulLogin(response, data);
        } catch (error: any) {
            await handleLoginError(error, response.authMethod);
        }
    };

    const prepareLoginPayload = (response: LoginData) => {
        const isProfilesEnabled = publisherMetaData.integration.isProfilesEnabled;
        const hasIntegrationFeatureFlag =
            publisherMetaData?.featureFlags[EFeatureFlag.INTEGRATION_PROFILES];
        const profileName = webStorageUtil.get(ELocalStorageKeys.INTEGRATION_PORFILE);

        // Handle profile image URL for social logins
        if (response.authMethod === ELoginMethods.FACEBOOK) {
            try {
                facebook
                    .getFacebookProfile(response.userToken!)
                    .then((url) => {
                        response.profileImageUrl = url;
                    })
                    .catch((error) => {
                        console.error('Failed to load profile picture', error);
                    });
            } catch (error) {
                console.error('Failed to load profile picture', error);
            }
        }
        if (response.authMethod === 'google') {
            google
                .getGoogleProfile(response.userToken!)
                .then((url) => {
                    response.profileImageUrl = url;
                })
                .catch((error) => {
                    console.error('Failed to load Google profile picture', error);
                });
        }

        // Add profile to user token if needed
        if (isProfilesEnabled && hasIntegrationFeatureFlag && profileName) {
            response.userToken = `${response.userToken}::${profileName}`;
        }

        const sessionData = webStorageUtil.get(ESessionStorageKeys.SESSION_DATA);
        const loginData: {
            data: LoginRequestData;
        } = {
            data: {
                publisherId: publisherMetaData.storeTheme.publisherId,
                localAddress: localAddress,
                sessionId: sessionData.id,
                ...response
            }
        };

        // Add OS information if feature flag is enabled
        if (publisherMetaData?.featureFlags.store_send_os_authlogin) {
            loginData.data.os = isIOS
                ? EPlatformType.IOS
                : isAndroid
                    ? EPlatformType.ANDROID
                    : EPlatformType.WEB;
        }

        if (response.authMethod === 'otp' && loginStateService.proofKey.get()) {
            loginStateService.setLoggingWithProofKey(true);
        }

        // Send login attempt event
        customEvents.sendCustomEvent(
            EEventsType.LOGIN_ATTEMPTED,
            loginPageService.getLoginEventsData({
                eventSharedProps: customEvents.getEventsSharedProps(),
                method: response.authMethod
            }),
            EStorePhase.PRE_LOGIN
        );

        // Prepare platform information
        const platformBase = getPlatformData().toUpperCase();
        const platform =
            isProfilesEnabled && hasIntegrationFeatureFlag && profileName
                ? `${platformBase}::${profileName}`
                : platformBase;

        return {
            ...loginData.data,
            platform,
            isIntegrationProfilesEnabled: isProfilesEnabled
        };
    };

    const performLogin = async (loginPayload: any): Promise<any> => {
      return await API.login.mutateAsync(loginPayload);
    };

    const processSuccessfulLogin = async (response: LoginData, data: LoginResponse) => {
        const loginDuration = calcLoginDuration();
        const loginDataToSend = {
            result: EResultOptions.SUCCESS,
            playerId: data.playerId
        };

        // Handle error code 100 as a special case
        if (data.errorCode === 100) {
            return failedLoginHandler(response.authMethod, data.errorMessage);
        }

        // Store session token in DB if available
        if (data.sessionToken) {
            await storeSessionTokenInDB(data.sessionToken, data.playerId);
        }

        // Save session token to local storage
        data.sessionToken && webStorageUtil.set(ELocalStorageKeys.SESSION_TOKEN, data.sessionToken);

        // Save player data
        webStorageUtil.set(ELocalStorageKeys.PLAYER_DATA, {
            playerId: data.playerId,
            playerName: data.playerName,
            playerCountry: data.playerCountry,
            profileImageUrl: data.profileImageUrl
        });

        customEvents.setDistinctId();

        // Send login result event
        customEvents.sendCustomEvent(
            EEventsType.LOGIN_RESULT,
            {
                authMethod: response.authMethod,
                ...loginDataToSend,
                login_duration: loginDuration,
                platform: getPlatformData()
            },
            EStorePhase.POST_LOGIN
        );

        // Send webhook event
        await sendPublisherWebhookLoginEvent({
            eventName: ELoginEvents.LOGIN_RESOLVED,
            method: response.authMethod,
            options: {
                ...loginDataToSend,
                duration: loginDuration
            }
        });

        console.log('response.authMethod', response.authMethod);

        if (response.authMethod !== ELoginMethods.DEEP_LINK) {
            navigate(`/shop`);
        }
    };

    const navigateToMode = (mode: ELoginMode) => {
        return mode !== 'providers' ? navigate(`./${mode}`) : navigate(-1);
    };

    const loginButtonClickWrapper = async (callback: Function, method: ELoginMethods) => {
        if (
            method === ELoginMethods.FACEBOOK ||
            method === ELoginMethods.GOOGLE ||
            method === ELoginMethods.APPLE
        ) {
            recordLoginStartTime();
            setDisplayLoader(true);
        }
        if (!loginPageService.isWebviewBrowser()) {
            customEvents.sendCustomEvent(
                EEventsType.LOGIN_CLICKED,
                loginPageService.getLoginEventsData({
                    eventSharedProps: customEvents.getEventsSharedProps(),
                    method
                }),
                EStorePhase.PRE_LOGIN
            );
        }
        sendPublisherWebhookLoginEvent({ eventName: ELoginEvents.LOGIN_METHOD_SELECTED, method });
        webStorageUtil.set(ESessionStorageKeys.REDIRECT_STATE, `${method}redirect`);
        callback();
    };

    const handleBackBtnClicked = async () => {
        const mode = loginStateService.mode.get() as ELoginMode;
        navigateToMode(ELoginMode.PROVIDERS);
        customEvents.sendCustomEvent(
            EEventsType.LOGIN_CANCELED,
            loginPageService.getLoginEventsData({
                eventSharedProps: customEvents.getEventsSharedProps()
            }),
            EStorePhase.PRE_LOGIN
        );
        sendPublisherWebhookLoginEvent({
            eventName: ELoginEvents.LOGIN_CANCELED,
            method: loginPageService.mapLoginModeToLoginMethod[mode]
        });
    };

    return {
        showCookiesOverlay: useObservable(loginStateService.showCookiesOverlay),
        proofKey: useObservable(loginStateService.proofKey),
        loggingWithProofKey: useObservable(loginStateService.loggingWithProofKey),
        otpToken: useObservable(loginStateService.otpToken),
        mode: useObservable(loginStateService.mode),
        hasConsent: useObservable(loginStateService.hasConsent),
        shouldShowTermsAlert: useObservable(loginStateService.shouldShowTermsAlert),
        isTermsAlertShaking: useObservable(loginStateService.isTermsAlertShaking),
        setShowCookiesOverlay: loginStateService.setShowCookiesOverlay,
        setProofKey: loginStateService.setProofKey,
        setLoggingWithProofKey: loginStateService.setLoggingWithProofKey,
        setOtpToken: loginStateService.setOtpToken,
        setMode: loginStateService.setMode,
        setHasConsent: loginStateService.setHasConsent,
        setShouldShowTermsAlert: loginStateService.setShouldShowTermsAlert,
        setIsTermsAlertShaking: loginStateService.setIsTermsAlertShaking,
        loginResponse,
        processSuccessfulLogin,
        failedLoginHandler,
        navigateToMode,
        loginButtonClickWrapper,
        handleBackBtnClicked,
        clear: loginStateService.clear
    };
};