import { Box, Select, styled as muiStyled } from '@mui/material';
import styled, { css } from 'styled-components';
import { Z_INDEX } from 'constants/zIndex';

interface StyledLoginPageProps {
  fontFamily: string;
  textColor: string;
  textSize: number;
  textWeight: number | string;
}

export const StyledLoginPage = styled.div<StyledLoginPageProps>`
  background-color: transparent;
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-family: ${(props) => props.fontFamily};
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 3em;

  & > * {
    margin-top: 20px;
  }

  a {
    color: white;
  }

  h2 {
    max-width: 90%;
    color: ${(props) => props.textColor};
    font-size: ${(props) => props.textSize}px;
    font-weight: ${(props) => props.textWeight};
    text-align: center;
    font-family: ${(props) => props.fontFamily};
  }

  button {
    font-family: ${(props) => props.fontFamily};
  }

  .usernameContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .orText {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: ${(props) => props.textColor};
    margin-top: 4px;
  }
`;

interface StyledLoginContainerProps {
  maxWidth: string;
  disabled: boolean;
}

export const StyledLoginContainer = styled.div<StyledLoginContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${(props) => props.maxWidth};
  width: 100%;

  .loginButton {
    ${({ disabled }) =>
      disabled &&
      css`
        pointer-events: none;
      `}
  }

  img.loginButton {
    margin-bottom: 12px;
    cursor: pointer;

    &:active {
      transform: scale(95%);
    }
  }

  span {
    text-align: center;
    max-width: 80%;
    font-size: 12px;

    & > span {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .MuiOtpInput-Box {
    margin-bottom: 20px;
    width: 500px;
    max-width: 90%;

    & div {
      width: 100%;
      aspect-ratio: 1/1;
    }

    input {
      width: 100%;
      height: 100%;
      padding: 8px;
      border: 1px solid white;
      box-sizing: border-box;
      color: white;
      font-size: 24px;
    }

    *:hover .MuiOutlinedInput-notchedOutline {
      border: 0 !important;
    }

    .Mui-focused {
      fieldset.MuiOutlinedInput-notchedOutline {
        border: 0;
      }
    }
  }

  .userTokenLink {
    color: white;
    text-decoration: underline;
    cursor: pointer;
    margin: 0 0 32px 0;
  }
`;

export const StyledIntegrationProfilePickerContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  z-index: ${Z_INDEX.CRITICAL};

  .MuiFormControl-root {
    align-items: center;
  }
` as any;

export const StyledIntegrationProfileSelect = styled(Select)<{ fontFamily: string }>`
  .MuiOutlinedInput-root {
    align-items: center;
  }

  .MuiOutlinedInput-input {
    padding-top: 4px;
    padding-bottom: 4px;
    color: white;
    font-size: 14px;
    font-family: ${(props) => props.fontFamily};
    font-weight: 400;
  }

  .MuiOutlinedInput-input.Mui-disabled {
    color: white;
    -webkit-text-fill-color: white;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: black !important;
  }

  .MuiSvgIcon-root {
    color: white;
  }
` as any;

export const getIntegrationProfileOptionStyles = (fontFamily: string) => ({
  PaperProps: {
    sx: {
      width: '100vw',
      maxWidth: '100vw',
      top: 'unset',
      transform: 'unset',
      left: '0!important',
      bgcolor: 'black',
      borderRadius: '0',
      '& .MuiMenuItem-root': {
        justifyContent: 'center',
        color: '#717188',
        fontFamily,
        fontSize: '14px',
        fontWeight: '400',
        '&:hover': {
          bgcolor: '#333',
        },
      },
      '& .Mui-selected': {
        color: 'white',
      },
    },
  },
});

export const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: auto;

  .logo {
    object-fit: contain;
    height: 100%;
    max-height: 150px;
  }

  #big.logo {
    width: 280px;
    height: 280px;
    max-height: fit-content;

    @media (min-width: 1024px) {
      width: 400px;
      height: 400px;
    }
  }
`;

export const StyledAlertContainer = styled.div<{ isVisible: boolean }>`
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
  height: 75px;
  z-index: ${Z_INDEX.FLOATING};

  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

interface StyledLoginTermsBlockProps {
  textColor: string;
  textSize: string;
}

export const StyledLoginTermsBlock = styled.div<StyledLoginTermsBlockProps>`
  color: ${(props) => props.textColor};
  text-align: center;
  max-width: 400px;
  font-size: ${(props) => props.textSize};
  .login-link {
    text-decoration: underline;
    cursor: pointer;
  }
`;

type IStyledConsentSectionProps = { shouldHighlight: boolean; textColor: string };

export const StyledConsentSection = muiStyled(Box, {
  shouldForwardProp: (prop) => prop !== 'shouldHighlight' && prop !== 'textColor',
})<IStyledConsentSectionProps>`
  display: flex;
  flex-direction: row;
  align-items: start;

  .consent-checkbox {
    color: ${({ textColor }) => textColor} !important;
    margin: 1px;
    padding: 0;
  }

  position: ${({ shouldHighlight }) => (shouldHighlight ? 'relative' : 'auto')};
  z-index: ${({ shouldHighlight }) => (shouldHighlight ? `${Z_INDEX.OVERLAY}` : 'auto')};
`;

export const StyledConsentHtml = styled.div<{ consentRequired: boolean }>`
  ${({ consentRequired }) =>
    consentRequired &&
    css`
      text-align: left;
    `};

  a {
    color: inherit;
  }

  font-size: 16px;

  .ql-size-small {
    font-size: 14px;
  }

  .ql-size-large {
    font-size: 18px;
  }

  .ql-size-huge {
    font-size: 20px;
  }
`;

export const StyledConsentOverlay = styled.div<{ hasConsent: boolean; height?: number }>`
  ${({ hasConsent, height }) =>
    !hasConsent &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.4);
      font-size: 12px;
      z-index: ${Z_INDEX.FLOATING};
      height: ${height ? `${height}px` : 'auto'};
    `}
` as any;
